<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('NumbersForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h2 class="mb-3">Numbers Training</h2>
      </b-col>
    </b-row>
    <div v-if="gameOver">
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="headline font-weight-bold mb-3">
            Score: {{ percentScore }}%
            {{ numbersList.length - missedQuestionIndexes.size }} /
            {{ numbersList.length }} Correct
          </h2>
        </b-col>
      </b-row>
      <b-row align-h="center" class="text-center">
        <b-col cols="12" sm="2" class="">
          <b-button class="mb-3" pill variant="primary" size="lg" @click="play"
            >Try Again?</b-button
          >
        </b-col>
        <b-col cols="12" sm="2" class="">
          <b-button
            class="mx-3 mb-3"
            pill
            variant="primary"
            size="lg"
            @click="newQuestions"
            >New Numbers</b-button
          >
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <p>Answers: {{ arrayPretty(numbersList) }}</p>
        </b-col>
      </b-row>
    </div>
    <div v-if="!gameOver">
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <h4 class="mb-3">Seconds Remaining: {{ recallSeconds }}</h4>
        </b-col>
      </b-row>
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h3 class="mb-3">
            Question {{ questionIndex }} of {{ Math.ceil(numberOfDigits / 2) }}
          </h3>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button class="mx-3" variant="light" size="lg" @click="skip"
            >Skip</b-button
          >
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6" class="justify-content-center text-center"> </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-row class="justify-content-center text-center">
            <b-col lg="2" md="4" sm="5" cols="6">
              <b-form-input
                v-model.lazy="userAnswer"
                type="tel"
                size="lg"
                autofocus
                @keyup.enter="submit"
              ></b-form-input> </b-col></b-row></b-col
      ></b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button pill variant="primary" size="lg" @click="submit"
            >Submit</b-button
          >
        </b-col>
      </b-row>
    </div>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <!-- Using modifiers -->
        <b-button
          v-b-toggle.collapse-numbers
          class="m-1"
          variant="info"
          :disabled="questionIndex <= 1"
          >Show my answers</b-button
        >

        <!-- Element to collapse -->
        <b-collapse id="collapse-numbers">
          <b-card>{{ arrayPretty(userAnswerList) }}</b-card>
        </b-collapse>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "NumbersRecall",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      recallSeconds: null,
      userAnswer: null,
      numbersList: null,
      numQuestions: null,
      questionList: null,
      numberOfDigits: null,
      answer: null,
      percentScore: null,
      userAnswerList: [],
      questionIndex: 0,
      missedQuestionIndexes: new Set(),
      correctAnswers: 0,
      gameOver: false,
    };
  },
  validations() {
    return {};
  },
  computed: {
    numbersToRemeberLength() {
      if (this.questionList != null) {
        return this.questionList.length;
      } else {
        return 0;
      }
    },
  },
  watch: {
    recallSeconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.recallSeconds--;
          }, 1000);
        } else if (value != null) {
          this.timeUp();
          this.gameOver = true;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    //Using questionIndex as watcher because missedQuestionIndexes is a set and vue doesn't watch changes within a set usually
    questionIndex: function () {
      if (this.missedQuestionIndexes.size === 0) {
        this.percentScore = 100;
      } else {
        this.percentScore = (
          ((this.numQuestions - this.missedQuestionIndexes.size) /
            this.numQuestions) *
          100
        ).toFixed(2);
      }
    },
    //Adds commas while user types larger numbers such as 1,000
    userAnswer: function (newValue) {
      let result = "";
      if (newValue.substring(0, 1) === "-") {
        result += "-";
      }
      result += newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.$nextTick(() => (this.userAnswer = result));
    },
  },
  methods: {
    submit() {
      if (this.userAnswer == this.answer) {
        this.successToast();
        this.correctAnswers++;
        setTimeout(() => {
          this.nextQuestion();
        }, "1500");
      } else {
        this.missedQuestionIndexes.add(this.questionIndex);
        this.failToast();
      }
    },
    nextQuestion() {
      if (this.questionIndex > 0) {
        this.userAnswerList.push(this.userAnswer);
      }
      this.questionIndex++;
      this.userAnswer = "";
      if (this.questionList != null && !this.questionList.length <= 0) {
        this.answer = this.questionList.shift();
      } else {
        this.gameOver = true;
      }
    },
    skip() {
      this.userAnswer = "Skip";
      this.missedQuestionIndexes.add(this.questionIndex);
      this.$bvToast.toast(this.answer, {
        title: "Answer",
        autoHideDelay: 3000,
        variant: "warning",
      });
      this.nextQuestion();
    },
    play() {
      this.gameOver = false;
      this.questionIndex = 0;
      this.correctAnswers = 0;
      this.userAnswerList = [];
      this.missedQuestionIndexes = new Set();
      if (this.getNumbersToRemember() != null) {
        this.questionList = [...this.getNumbersToRemember()];
        this.numbersList = [...this.questionList];
        this.numQuestions = this.numbersList.length;
        this.recallSeconds = this.getNumbersForm().recallSeconds;
        this.numberOfDigits = this.getNumbersForm().numberOfDigits;

        this.nextQuestion();
      }
    },
    newQuestions() {
      this.createNumbersList(this.getNumbersForm());

      this.$router.push("/NumbersViewing");
    },
    timeUp() {
      this.gameOver = true;

      // percentage being calculated correct requires questiton index to update so watcher is fired
      for (
        this.questionIndex;
        this.questionIndex <= this.numQuestions;
        this.questionIndex++
      ) {
        this.missedQuestionIndexes.add(this.questionIndex);
      }
    },
    arrayPretty(array) {
      let arrayStr = "";

      if (array != null) {
        for (let item of array) {
          arrayStr += item + " ";
        }
      }

      return arrayStr;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    generateQuestions() {},
    ...mapMutations([]),
    ...mapActions(["createNumbersList"]),
    ...mapGetters(["getNumbersForm", "getNumbersToRemember"]),
    successToast() {
      this.$bvToast.toast(this.answer, {
        title: "Correct!",
        autoHideDelay: 3000,
        variant: "success",
      });
    },
    failToast() {
      this.$bvToast.toast("Answer" + " ≠ " + this.userAnswer, {
        title: "Incorrect",
        autoHideDelay: 3000,
        variant: "danger",
      });
    },
  },
  mounted() {
    if (this.getNumbersForm() == null) {
      this.$router.push("/NumbersForm");
    } else {
      this.play();
    }
  },
};
</script>
