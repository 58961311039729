<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('NumbersForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h3 class="mb-3">Remember the Numbers</h3>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="12" class="justify-content-center text-center">
        <h4 class="mb-3">Seconds Remaining: {{ studySeconds }}</h4>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <b-form-spinbutton
          id="sb-days"
          v-model="spinButtonIndex"
          :formatter-fn="numberGroupFormatter"
          size="lg"
          min="0"
          :max="numbersToRemeberLength - 1"
        ></b-form-spinbutton>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <!-- Using modifiers -->
        <b-button
          v-b-toggle.collapse-numbers
          class="m-1"
          variant="outline-secondary"
          >Show All Numbers</b-button
        >

        <!-- Element to collapse -->
        <b-collapse id="collapse-numbers">
          <b-card>{{ allNumbersView }}</b-card>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <router-link to="/IntermissionTime/numbers"
          ><b-button pill variant="secondary"
            >Skip Wait Time</b-button
          ></router-link
        >
      </b-col>
    </b-row>
    <div></div>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "NumbersViewing",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      studySeconds: null,
      numbersList: null,
      spinButtonIndex: 0,
    };
  },
  validations() {
    return {};
  },
  computed: {
    allNumbersView() {
      let numberStr = "";

      if (this.numbersList != null) {
        for (let number of this.numbersList) {
          numberStr += number + " ";
        }
      }

      return numberStr;
    },

    numbersToRemeberLength() {
      if (this.numbersList != null) {
        return this.numbersList.length;
      } else {
        return 0;
      }
    },
  },
  watch: {
    studySeconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.studySeconds--;
          }, 1000);
        } else if (value != null) {
          this.$router.push({
            name: "IntermissionTime",
            params: {
              intermissionType: "numbers",
            },
          });
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    numberGroupFormatter(spinButtonIndex) {
      if (this.numbersList != null) {
        return this.numbersList[spinButtonIndex];
      } else {
        return [];
      }
    },

    generateQuestions() {},
    ...mapMutations([]),
    ...mapActions(["createNumbersList"]),
    ...mapGetters(["getNumbersForm", "getNumbersToRemember"]),
  },
  mounted() {
    if (this.getNumbersForm() == null) {
      this.$router.push("/NumbersForm");
    } else {
      this.numbersList = this.getNumbersToRemember();
      this.studySeconds = this.getNumbersForm().studySeconds;
    }
  },
};
</script>
