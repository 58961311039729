function createMathQuestions(payload) {
  let questionList = [];
  let numDigitsA = payload.numDigitsA;
  let numDigitsB = payload.numDigitsB;

  for (let i = 0; i < payload.numQuestions; i++) {
    let factors = this.generateFactors(numDigitsA, numDigitsB);

    if (payload.mathType === "/") {
      while (factors[0] < factors[1] || factors[0] % factors[1] != 0) {
        factors = this.generateFactors(numDigitsA, numDigitsB);
      }
    }

    let answer = this.getAnswer(factors, payload.mathType);
    factors = this.numberWithCommas(factors);
    answer = answer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    questionList.push({
      question: this.questionString(factors, payload.mathType),
      answer: answer,
    });
  }
  return questionList;
}

function generateFactors(numDigitsA, numDigitsB) {
  let factors = [];
  factors.push(this.getRandomInt(numDigitsA));
  factors.push(this.getRandomInt(numDigitsB));

  factors = this.factorsValidation(factors);

  return factors;
}

function factorsValidation(factors) {
  if (factors[0].length > 1) {
    factors[0] = this.removeLeadingZeros(factors[0]);
  }
  if (factors[1].length > 1) {
    factors[1] = this.removeLeadingZeros(factors[1]);
  }

  return factors;
}

function numberWithCommas(factors) {
  for (let i = 0; i < factors.length; i++) {
    factors[i] = factors[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return factors;
}

function removeLeadingZeros(number) {
  let regExp = /^0[0-9].*$/;
  let hasLeadingZeros = regExp.test(number);

  if (hasLeadingZeros) {
    let numString = number.toString();
    let cleanedString = this.randomNonZeroInt().toString();
    for (let i = 1; i < numString.length; i++) {
      cleanedString += numString.charAt(i);
    }
    number = cleanedString;
  }

  return number;
}

function randomNonZeroInt() {
  let max = 9;
  let min = 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function questionString(factors, mathType) {
  let questionString = "";

  for (let i = 0; i < factors.length; i++) {
    questionString += factors[i].toString();
    if (i + 1 != factors.length) {
      questionString += " " + mathType + " ";
    }
  }
  return questionString;
}

function getAnswer(factors, mathType) {
  switch (mathType) {
    case "+":
      return this.add(factors);
    case "-":
      return this.subtract(factors);
    case "*":
      return this.multiply(factors);
    case "/":
      return this.divide(factors);
    default:
      console.log(factors);
  }
}
function multiply(factors) {
  let product = 1;

  for (let multiplicand of factors) {
    product *= parseInt(multiplicand);
  }

  return product;
}

function divide(factors) {
  let quotient = parseInt(factors[0]);

  for (let i = 1; i < factors.length; i++) {
    quotient /= parseInt(factors[i]);
  }

  return quotient;
}

function subtract(factors) {
  let difference = parseInt(factors[0]);

  for (let i = 1; i < factors.length; i++) {
    difference -= parseInt(factors[i]);
  }

  return difference;
}

function add(factors) {
  let sum = 0;

  for (let addend of factors) {
    sum += parseInt(addend);
  }
  return sum;
}

function getRandomInt(n) {
  n = parseInt(n);
  var add = 1,
    max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

  if (n > max) {
    return getRandomInt(max) + getRandomInt(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
}

export default {
  createMathQuestions,
  getRandomInt,
  getAnswer,
  add,
  multiply,
  divide,
  subtract,
  questionString,
  generateFactors,
  removeLeadingZeros,
  factorsValidation,
  randomNonZeroInt,
  numberWithCommas,
};
