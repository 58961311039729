import NumberService from "../services/NumberService.js";

const CardsModule = {
  state: {
    numbersForm: null, //contains data: studySeconds, intermissionSeconds, recallSeconds, numberOfDigits //once has been set
    numbersToRemember: null,
  },
  getters: {
    getNumbersForm: (state) => {
      return state.numbersForm;
    },
    getNumbersToRemember: (state) => {
      return state.numbersToRemember;
    },
  },
  mutations: {
    setNumbersForm(state, numbersForm) {
      state.numbersForm = numbersForm;
    },
    setNumbersToRemember(state, numbersToRemember) {
      state.numbersToRemember = numbersToRemember;
    },
  },
  actions: {
    async createNumbersList({ commit }, payload) {
      commit("setNumbersForm", payload);
      let numbersToRemember = NumberService.createNumbersToRemember(payload);
      commit("setNumbersToRemember", numbersToRemember);
    },
  },
};

export default CardsModule;
