<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('CardsForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h3 class="mb-3">Remember the Cards</h3>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="12" class="justify-content-center text-center">
        <h4 class="mb-3">Seconds Remaining: {{ studySeconds }}</h4>
      </b-col>
    </b-row>
    <b-row class="m-4 text-center">
      <b-col cols="12">
        <h3 class="mb-3" v-if="cardsList">
          Card {{ cardViewingIndex + 1 }} of {{ cardsList.length }}
        </h3>
      </b-col>
    </b-row>
    <div v-if="cardsList != null">
      <b-row align-h="center">
        <b-col cols="10" class="justify-content-center text-center">
          <b-img
            :src="
              require(`@/assets/poker_cards/${cardsList[cardViewingIndex]}.svg`)
            "
            alt="Poker Card Image"
          ></b-img>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="6" class="justify-content-center text-center">
          <!-- Using modifiers -->
          <b-button
            :disabled="cardViewingIndex <= 0"
            class="m-1"
            variant="primary"
            @click="cardViewingIndex--"
            >Previous</b-button
          >
        </b-col>
        <b-col cols="6" class="justify-content-center text-center">
          <!-- Using modifiers -->
          <b-button
            :disabled="cardViewingIndex >= cardsList.length - 1"
            class="m-1"
            variant="primary"
            @click="cardViewingIndex++"
            >Next</b-button
          >
        </b-col>
      </b-row>
    </div>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <!-- Element to collapse -->
        <b-collapse id="collapse-numbers">
          <b-card>{{ allNumbersView }}</b-card>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <router-link to="/IntermissionTime/cards"
          ><b-button pill variant="secondary"
            >Skip Wait Time</b-button
          ></router-link
        >
      </b-col>
    </b-row>
    <div></div>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "CardsViewing",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      studySeconds: null,
      cardsList: null,
      cardViewingIndex: 0,
    };
  },
  validations() {
    return {};
  },
  computed: {
    allNumbersView() {
      let numberStr = "";

      if (this.cardsList != null) {
        for (let number of this.cardsList) {
          numberStr += number + " ";
        }
      }

      return numberStr;
    },

    numbersToRemeberLength() {
      if (this.cardsList != null) {
        return this.cardsList.length;
      } else {
        return 0;
      }
    },
  },
  watch: {
    studySeconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.studySeconds--;
          }, 1000);
        } else if (value != null) {
          this.$router.push({
            name: "IntermissionTime",
            params: {
              intermissionType: "cards",
            },
          });
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    numberGroupFormatter(spinButtonIndex) {
      if (this.cardsList != null) {
        return this.cardsList[spinButtonIndex];
      } else {
        return [];
      }
    },

    generateQuestions() {},
    ...mapMutations([]),
    ...mapActions(["createCardsList"]),
    ...mapGetters(["getCardsForm", "getCardsToRemember"]),
  },
  mounted() {
    if (this.getCardsForm() == null) {
      this.$router.push("/CardsForm");
    } else {
      this.cardsList = this.getCardsToRemember();
      this.studySeconds = this.getCardsForm().studySeconds;
    }
  },
};
</script>
