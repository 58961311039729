import MathService from "../services/MathService.js";

const MathModule = {
  state: {
    mathForm: null, //contains data: numQuestions, mathType, numDigitsA, numDigitsB //once has been set
    questions: null,
  },
  getters: {
    getMathForm: (state) => {
      return state.mathForm;
    },
    getQuestions: (state) => {
      return state.questions;
    },
  },
  mutations: {
    setMathForm(state, mathForm) {
      state.mathForm = mathForm;
    },
    setQuestions(state, questions) {
      state.questions = questions;
    },
  },
  actions: {
    async generateQuestions({ commit }, payload) {
      let questions = MathService.createMathQuestions(payload);
      commit("setQuestions", questions);
    },
  },
};

export default MathModule;
