//payload contains data: studySeconds, intermissionTime, recallSeconds, numberOfDigits
function createNumbersToRemember(payload) {
  let numbersList = generateRandomNumbers(payload.numberOfDigits);

  return numbersList;
}

function generateRandomNumbers(numberOfDigits) {
  let numbers = [];

  for (let i = 0; i < numberOfDigits; i++) {
    let numberGroup = randomInt().toString();
    if (i + 1 < numberOfDigits) {
      i++;
      numberGroup += randomInt().toString();
    }

    numbers.push(numberGroup);
  }

  return numbers;
}

function randomInt() {
  let max = 9;
  let min = 0;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  createNumbersToRemember,
  generateRandomNumbers,
  randomInt,
};
