import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MathForm from "../components/MathForm.vue";
import MathTraining from "../components/MathTraining.vue";
import NotFound from "../components/NotFound.vue";
import NumbersForm from "../components/NumbersForm.vue";
import NumbersViewing from "../components/NumbersViewing.vue";
import NumbersRecall from "../components/NumbersRecall.vue";
import IntermissionTime from "../components/IntermissionTime.vue";
import CardsForm from "../components/CardsForm.vue";
import CardsViewing from "../components/CardsViewing.vue";
import CardsRecall from "../components/CardsRecall.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/MathForm",
    name: "MathForm",
    component: MathForm,
  },
  {
    path: "/MathTraining",
    name: "MathTraining",
    component: MathTraining,
  },
  {
    path: "/NumbersForm",
    name: "NumbersForm",
    component: NumbersForm,
  },
  {
    path: "/NumbersViewing",
    name: "NumbersViewing",
    component: NumbersViewing,
  },
  {
    path: "/CardsForm",
    name: "CardsForm",
    component: CardsForm,
  },
  {
    path: "/CardsViewing",
    name: "CardsViewing",
    component: CardsViewing,
  },
  {
    path: "/CardsRecall",
    name: "CardsRecall",
    component: CardsRecall,
  },
  {
    path: "/NumbersRecall",
    name: "NumbersRecall",
    component: NumbersRecall,
  },
  {
    path: "/IntermissionTime/:intermissionType",
    name: "IntermissionTime",
    component: IntermissionTime,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
