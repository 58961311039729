<template class="">
  <b-overlay :show="showOverlay" rounded="sm">
    <b-container>
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="mb-3">Select options for cards training</h2>
        </b-col>
      </b-row>
      <b-row class="" align-h="center">
        <b-col cols="6" md="4" lg="3">
          <label for="numberOfCards" style="font-size: 22px"
            >Number of Cards</label
          >
        </b-col>
        <b-col cols="6" md="3">
          <b-form-input
            id="numberOfCards"
            v-model="$v.form.numberOfCards.$model"
            :state="validateState('numberOfCards')"
            type="number"
            size="lg"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.numberOfCards.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numberOfCards.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numberOfCards.digitsLimit"
            >Max
            {{ maxCards.toLocaleString("en") }} digits</b-form-invalid-feedback
          >
        </b-col>
      </b-row>
      <b-row class="" align-h="center">
        <b-col cols="6" md="4" lg="3">
          <label for="studySeconds" style="font-size: 22px"
            >Seconds to Study</label
          >
        </b-col>
        <b-col cols="6" md="3">
          <b-form-input
            id="studySeconds"
            v-model="$v.form.studySeconds.$model"
            :state="validateState('studySeconds')"
            type="number"
            size="lg"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.studySeconds.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.studySeconds.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.studySeconds.secondsLimit"
            >Max
            {{ maxSeconds.toLocaleString("en") }}
            seconds</b-form-invalid-feedback
          >
        </b-col></b-row
      >
      <b-row class="" align-h="center">
        <b-col cols="6" md="4" lg="3">
          <label for="intermissionSeconds" style="font-size: 22px"
            >Intermission Seconds</label
          >
        </b-col>
        <b-col cols="6" md="3">
          <b-form-input
            id="intermissionSeconds"
            v-model="$v.form.intermissionSeconds.$model"
            :state="validateState('intermissionSeconds')"
            type="number"
            size="lg"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.intermissionSeconds.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            v-if="!$v.form.intermissionSeconds.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            v-if="!$v.form.intermissionSeconds.secondsLimit"
            >Max
            {{ maxSeconds.toLocaleString("en") }}
            seconds</b-form-invalid-feedback
          >
        </b-col></b-row
      >
      <b-row class="" align-h="center">
        <b-col cols="6" md="4" lg="3">
          <label for="se" style="font-size: 22px">Seconds to Recall</label>
        </b-col>
        <b-col cols="6" md="3">
          <b-form-input
            id="recallSeconds"
            v-model="$v.form.recallSeconds.$model"
            :state="validateState('recallSeconds')"
            type="number"
            size="lg"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.recallSeconds.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.recallSeconds.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.recallSeconds.secondsLimit"
            >Max
            {{ maxSeconds.toLocaleString("en") }}
            seconds</b-form-invalid-feedback
          >
        </b-col></b-row
      >
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <b-button @click="train" variant="success" size="lg">Train</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "CardsForm",
  setup: () => ({ v$: useVuelidate() }),

  data: () => ({
    form: {
      numberOfCards: 6,
      studySeconds: 60,
      intermissionSeconds: 15,
      recallSeconds: 60,
    },
    maxSeconds: 3600,
    maxCards: 3000,
    showOverlay: false,
    makingQuestions: true,
  }),
  validations() {
    return {
      form: {
        numberOfCards: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.numberOfCards);
          },
          digitsLimit: function () {
            return !(this.form.numberOfCards > this.maxCards);
          },
        },
        studySeconds: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.studySeconds);
          },
          secondsLimit: function () {
            return !(this.form.studySeconds > this.maxSeconds);
          },
        },
        intermissionSeconds: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.intermissionSeconds);
          },
          secondsLimit: function () {
            return !(this.form.intermissionSeconds > this.maxSeconds);
          },
        },
        recallSeconds: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.recallSeconds);
          },
          secondsLimit: function () {
            return !(this.form.recallSeconds > this.maxSeconds);
          },
        },
      },
    };
  },

  methods: {
    greaterThanZero(numDigits) {
      if (numDigits != "" && numDigits <= 0) {
        return false;
      } else {
        return true;
      }
    },
    train() {
      this.showOverlay = true;
      const result = this.validate();
      if (result) {
        this.createCardsList(this.form);

        this.$router.push("/CardsViewing");
      }
      this.showOverlay = false;
    },
    async validate() {
      this.$v.form.$touch();
      return await this.v$.$validate();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    ...mapMutations(["numbersForm"]),
    ...mapActions(["createCardsList"]),
    ...mapGetters([]),
  },
  mounted() {},
};
</script>
