//payload contains data: studySeconds, intermissionTime, recallSeconds, numberOfCards
let cardIndexes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  40, 41, 42, 43, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93,
];

function createCardsToRemember(payload) {
  let cardsList = generateRandomNumbers(payload.numberOfCards);

  return cardsList;
}

function generateRandomNumbers(numberOfCards) {
  let numbers = [];

  for (let i = 0; i < numberOfCards; i++) {
    numbers.push(cardIndexes[randomInt()]);
  }
  return numbers;
}

function randomInt() {
  let max = 51;
  let min = 0;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  createCardsToRemember,
  generateRandomNumbers,
  randomInt,
};
