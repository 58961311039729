<template>
  <v-parallax
    height="300"
    src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
  >
    <v-container>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Welcome to Math and Memory
          </h1>
        </v-col>

        <b-col class="mb-5" cols="12">
          <h2 class="headline font-weight-bold mb-3">Select which to learn</h2>
          <router-link to="/MathForm"
            ><b-button class="mx-3 px-8" variant="light"
              >Math</b-button
            ></router-link
          >
          <b-dropdown
            id="dropdown-left"
            text="Memory"
            variant="light"
            class="m-2 px-8"
          >
            <router-link to="/NumbersForm"
              ><b-dropdown-item href="/NumbersForm">Numbers</b-dropdown-item>
            </router-link>
            <router-link to="/CardsForm"
              ><b-dropdown-item href="/CardsForm">Cards</b-dropdown-item>
            </router-link>
          </b-dropdown>
        </b-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  name: "HomePage",

  data: () => ({}),
};
</script>
