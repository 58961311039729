<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('CardsForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h2 class="mb-3">Cards Training</h2>
      </b-col>
    </b-row>
    <div v-if="gameOver">
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="headline font-weight-bold mb-3">
            Score: {{ percentScore }}%
            {{ cardsList.length - missedQuestionIndexes.size }} /
            {{ cardsList.length }} Correct
          </h2>
        </b-col>
      </b-row>
      <b-row align-h="center" class="text-center">
        <b-col cols="12" sm="2" class="">
          <b-button class="mb-3" pill variant="primary" size="lg" @click="play"
            >Try Again?</b-button
          >
        </b-col>
        <b-col cols="12" sm="2" class="">
          <b-button
            class="mx-3 mb-3"
            pill
            variant="primary"
            size="lg"
            @click="newQuestions"
            >New Cards</b-button
          >
        </b-col>
      </b-row>
    </div>
    <div v-if="!gameOver" class="my-3">
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <h4 class="mb-3">Seconds Remaining: {{ recallSeconds }}</h4>
        </b-col>
      </b-row>
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h3 class="mb-3" v-if="cardsList">
            Card {{ questionIndex + 1 }} of {{ cardsList.length }}
          </h3>
        </b-col>
      </b-row>

      <b-row align-h="center" class="my-3">
        <b-tabs content-class="mt-3" align="center">
          <b-tab
            title="Diamonds"
            id="tab-diamonds"
            class="mt-2"
            @click="cardSelected = null"
          >
            <div v-if="cardsList">
              <b-row align-h="center" v-if="!cardSelected">
                <b-col
                  cols="10"
                  offset="1"
                  class="justify-content-center text-center"
                >
                  <v-virtual-scroll
                    :items="diamondsIndexList"
                    height="300"
                    item-height="120"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item">
                        <b-img
                          :id="item"
                          :src="require(`@/assets/poker_cards/${item}.svg`)"
                          alt="Poker Card Image"
                          @click="cardSelected = item"
                        ></b-img
                      ></v-list-item>
                    </template>
                  </v-virtual-scroll>
                </b-col>
              </b-row>
              <b-row align-h="center" v-else>
                <b-col cols="10" class="justify-content-center text-center">
                  <b-img
                    :src="require(`@/assets/poker_cards/${cardSelected}.svg`)"
                    alt="Poker Card Image"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab
            title="Clubs"
            id="tab-clubs"
            class="mt-2"
            @click="cardSelected = null"
          >
            <div v-if="cardsList">
              <b-row align-h="center" v-if="!cardSelected">
                <b-col
                  cols="10"
                  offset="1"
                  class="justify-content-center text-center"
                >
                  <v-virtual-scroll
                    :items="clubsIndexList"
                    height="300"
                    item-height="120"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item">
                        <b-img
                          :id="item"
                          :src="require(`@/assets/poker_cards/${item}.svg`)"
                          alt="Poker Card Image"
                          @click="cardSelected = item"
                        ></b-img
                      ></v-list-item>
                    </template>
                  </v-virtual-scroll>
                </b-col>
              </b-row>
              <b-row align-h="center" v-else>
                <b-col cols="10" class="justify-content-center text-center">
                  <b-img
                    :src="require(`@/assets/poker_cards/${cardSelected}.svg`)"
                    alt="Poker Card Image"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab
            title="Spades"
            id="tab-spades"
            class="mt-2"
            @click="cardSelected = null"
          >
            <div v-if="cardsList">
              <b-row align-h="center" v-if="!cardSelected">
                <b-col
                  cols="10"
                  offset="1"
                  class="justify-content-center text-center"
                >
                  <v-virtual-scroll
                    :items="spadesIndexList"
                    height="300"
                    item-height="120"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item">
                        <b-img
                          :id="item"
                          :src="require(`@/assets/poker_cards/${item}.svg`)"
                          alt="Poker Card Image"
                          @click="cardSelected = item"
                        ></b-img
                      ></v-list-item>
                    </template>
                  </v-virtual-scroll>
                </b-col>
              </b-row>
              <b-row align-h="center" v-else>
                <b-col cols="10" class="justify-content-center text-center">
                  <b-img
                    :src="require(`@/assets/poker_cards/${cardSelected}.svg`)"
                    alt="Poker Card Image"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
          </b-tab>

          <b-tab
            title="Hearts"
            id="tab-hearts"
            class="mt-2"
            @click="cardSelected = null"
          >
            <div v-if="cardsList">
              <b-row align-h="center" v-if="!cardSelected">
                <b-col
                  cols="10"
                  offset="1"
                  class="justify-content-center text-center"
                >
                  <v-virtual-scroll
                    :items="heartsIndexList"
                    height="300"
                    item-height="120"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item :key="item">
                        <b-img
                          :id="item"
                          :src="require(`@/assets/poker_cards/${item}.svg`)"
                          alt="Poker Card Image"
                          @click="cardSelected = item"
                        ></b-img>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </b-col>
              </b-row>
              <b-row align-h="center" v-else>
                <b-col cols="10" class="justify-content-center text-center">
                  <b-img
                    :src="require(`@/assets/poker_cards/${cardSelected}.svg`)"
                    alt="Poker Card Image"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-row>

      <b-row align-h="center" class="my-3" v-if="cardSelected">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button pill variant="primary" size="lg" @click="submit"
            >Submit</b-button
          >
        </b-col>
      </b-row>
      <b-row align-h="center" class="my-3" v-else>
        <b-col cols="12" class="justify-content-center text-center">
          <p>Select a card</p>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="cardSelected">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button
            pill
            class="mx-3"
            variant="light"
            size="lg"
            @click="cardSelected = null"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button class="mx-3" variant="secondary" size="lg" @click="skip"
            >Skip</b-button
          >
        </b-col>
      </b-row>
    </div>

    <div v-if="gameOver">
      <b-row align-h="center" class="text-center">
        <b-col cols="6" md="3" lg="2" class="">
          <h3 class="headline font-weight-bold mb-0 pb-0">Answers</h3>
          <h3 class="headline font-weight-bold">(Left)</h3>
        </b-col>
        <b-col cols="6" md="3" lg="2" class="">
          <h3 class="headline font-weight-bold mb-0 pb-0">Your Answers</h3>
          <h3 class="headline font-weight-bold">(Right)</h3>
        </b-col>
      </b-row>

      <b-row
        align-h="center"
        class="my-3"
        v-if="cardsList && userInitialAnswerArray"
      >
        <b-col
          offset-lg="1"
          lg="5"
          md="12"
          cols="12"
          class="d-flex justify-content-center text-center border"
        >
          <v-virtual-scroll
            :items="cardsList"
            height="300"
            item-height="120"
            v-if="userInitialAnswerArray.length === cardsList.length"
          >
            <template v-slot:default="{ item, index }">
              <v-list-item :key="item">
                <b-img
                  :id="item"
                  :src="require(`@/assets/poker_cards/${item}.svg`)"
                  alt="Poker Card Image"
                  @click="cardSelected = item"
                ></b-img>
                <b-img
                  :id="item"
                  :src="
                    require(`@/assets/poker_cards/${userInitialAnswerArray[index]}.svg`)
                  "
                  alt="Poker Card Image"
                  @click="cardSelected = item"
                ></b-img
              ></v-list-item>
            </template>
          </v-virtual-scroll>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "CardsRecall",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      recallSeconds: null,
      userAnswer: null,
      cardsList: null,
      numQuestions: null,
      questionList: null,
      numberOfCards: null,
      answer: null,
      percentScore: null,
      userAnswerList: [],
      questionIndex: 0,
      missedQuestionIndexes: null,
      correctAnswers: 0,
      gameOver: false,

      diamondsIndexList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      clubsIndexList: [31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43],
      spadesIndexList: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
      heartsIndexList: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93],
      cardSelected: null,
      userInitialAnswersMap: null,
    };
  },
  validations() {
    return {};
  },
  computed: {
    userInitialAnswerArray() {
      let array = Array.from(this.userInitialAnswersMap.values());
      return array;
    },
  },
  watch: {
    recallSeconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.recallSeconds--;
          }, 1000);
        } else if (value != null && !this.gameOver) {
          this.timeUp();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    //Using questionIndex as watcher because missedQuestionIndexes is a set and vue doesn't watch changes within a set usually
    questionIndex: function () {
      if (this.missedQuestionIndexes.size === 0) {
        this.percentScore = 100;
      } else {
        this.percentScore = (
          ((this.numQuestions - this.missedQuestionIndexes.size) /
            this.numQuestions) *
          100
        ).toFixed(2);
      }
    },
    //Adds commas while user types larger numbers such as 1,000
    userAnswer: function (newValue) {
      let result = "";
      if (newValue.substring(0, 1) === "-") {
        result += "-";
      }
      result += newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.$nextTick(() => (this.userAnswer = result));
    },
  },
  methods: {
    submit() {
      if (!this.userInitialAnswersMap.has(this.questionIndex)) {
        this.userInitialAnswersMap.set(this.questionIndex, this.cardSelected);
      }

      if (this.cardSelected == this.answer) {
        this.successToast();
        this.correctAnswers++;
        this.cardSelected = null;
        setTimeout(() => {
          this.nextQuestion();
        }, "1500");
      } else {
        this.missedQuestionIndexes.add(this.questionIndex);
        this.failToast();
      }
    },
    nextQuestion() {
      if (this.questionIndex >= 0) {
        this.userAnswerList.push(this.userAnswer);
      }
      this.questionIndex++;
      this.userAnswer = "";
      if (this.questionList != null && this.questionList.length > 0) {
        this.answer = this.questionList.shift();
      } else {
        this.gameOver = true;
      }
    },
    skip() {
      if (!this.userInitialAnswersMap.has(this.questionIndex)) {
        this.userInitialAnswersMap.set(this.questionIndex, "skip");
      }
      this.userAnswer = "Skip";
      this.missedQuestionIndexes.add(this.questionIndex);
      this.cardSelected = null;

      this.nextQuestion();
    },
    play() {
      this.gameOver = false;
      this.questionIndex = 0;
      this.correctAnswers = 0;
      this.userAnswerList = [];
      this.missedQuestionIndexes = new Set();
      this.userInitialAnswersMap = new Map();
      if (this.getCardsToRemember() != null) {
        this.questionList = [...this.getCardsToRemember()];
        this.cardsList = [...this.questionList];
        this.numQuestions = this.cardsList.length;
        this.recallSeconds = this.getCardsForm().recallSeconds;
        this.numberOfCards = this.getCardsForm().numberOfCards;
        this.answer = this.questionList.shift();
      }
    },
    newQuestions() {
      this.createCardsList(this.getCardsForm());

      this.$router.push("/NumbersViewing");
    },
    timeUp() {
      this.gameOver = true;

      // percentage being calculated correct requires questiton index to update so watcher is fired
      for (
        this.questionIndex;
        this.questionIndex < this.numQuestions;
        this.questionIndex++
      ) {
        this.missedQuestionIndexes.add(this.questionIndex);
        this.userInitialAnswersMap.set(this.questionIndex, "noAnswer");
      }
    },
    arrayPretty(array) {
      let arrayStr = "";

      if (array != null) {
        for (let item of array) {
          arrayStr += item + " ";
        }
      }

      return arrayStr;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    generateQuestions() {},
    ...mapMutations([]),
    ...mapActions(["createCardsList"]),
    ...mapGetters(["getCardsForm", "getCardsToRemember", "getHeartsIndexList"]),
    successToast() {
      this.$bvToast.toast("Correct!", {
        title: "Great Job",
        autoHideDelay: 3000,
        variant: "success",
      });
    },
    failToast() {
      this.$bvToast.toast("Incorrect", {
        title: "Try Again",
        autoHideDelay: 3000,
        variant: "danger",
      });
    },
  },
  mounted() {
    if (this.getCardsForm() == null) {
      this.$router.push("/CardsForm");
    } else {
      this.play();
    }
  },
  created() {},
};
</script>
