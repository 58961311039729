<template class="">
  <b-overlay :show="showOverlay" rounded="sm">
    <b-container>
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="headline font-weight-bold mb-3">
            Select options for math training
          </h2>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Math type:">
            <b-form-radio-group
              size="lg"
              id="checkbox-group-1"
              :options="mathTypeOptions"
              v-model="$v.form.mathType.$model"
              :state="validateState('mathType')"
            ></b-form-radio-group>
            <b-form-feedback class="d-block" v-if="$v.form.mathType.$error"
              >This is a required field.</b-form-feedback
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" offset="">
          <h6>
            In an example problem a + b = c, enter how many digits wanted for a
            and b.
          </h6></b-col
        >
      </b-row>
      <b-row class="" align-h="center">
        <b-col cols="6" md="2">
          <label for="numDigitsA" style="font-size: 22px">A:</label>
          <b-form-input
            id="numDigitsA"
            v-model="$v.form.numDigitsA.$model"
            :state="validateState('numDigitsA')"
            type="number"
            size="lg"
            placeholder="A"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.numDigitsA.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numDigitsA.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numDigitsA.digitsLimit"
            >Max {{ factorDigitsMax }} digits</b-form-invalid-feedback
          >
        </b-col>
        <b-col cols="6" md="2">
          <label for="numDigitsB" style="font-size: 22px">B:</label>
          <b-form-input
            id="numDigitsB"
            v-model="$v.form.numDigitsB.$model"
            :state="validateState('numDigitsB')"
            type="number"
            size="lg"
            placeholder="B"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!$v.form.numDigitsB.required"
            >This is a required field.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numDigitsB.greaterThanZero"
            >Must be greater than 0</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numDigitsB.digitsLimit"
            >Max {{ factorDigitsMax }} digits</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.form.numDigitsB.divideRule"
            >Set B less than or equal to A.</b-form-invalid-feedback
          ></b-col
        ></b-row
      >
      <b-row class="m-4 text-center" align-h="center">
        <b-col cols="7" md="4">
          <b-form-group label="Number of questions:">
            <b-form-input
              size="lg"
              v-model="$v.form.numQuestions.$model"
              :state="validateState('numQuestions')"
              type="number"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.form.numQuestions.required"
              >This is a required field.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!$v.form.numQuestions.maxValue"
              >Max {{ maxQuestions }} questions</b-form-invalid-feedback
            >
            <b-form-invalid-feedback
              v-if="!$v.form.numQuestions.greaterThanZero"
              >Must be greater than 0</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <b-button @click="train" variant="success" size="lg">Train</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "MathForm",
  setup: () => ({ v$: useVuelidate() }),

  data: () => ({
    form: {
      mathType: "+",
      numQuestions: 10,
      numDigitsA: 1,
      numDigitsB: 1,
    },
    factorDigitsMax: null,
    maxQuestions: null,
    showOverlay: false,
    makingQuestions: true,
    mathTypeOptions: [
      { text: "Add", value: "+" },
      { text: "Subtract", value: "-" },
      { text: "Multiply", value: "*" },
      { text: "Divide", value: "/" },
    ],
  }),
  validations() {
    return {
      form: {
        numQuestions: {
          required,
          maxValue: () => {
            return this.setMaxQuestions(this.form.numQuestions);
          },
          greaterThanZero: () => {
            return this.greaterThanZero(this.form.numQuestions);
          },
        },
        mathType: {
          required,
        },
        numDigitsA: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.numDigitsA);
          },
          digitsLimit: function () {
            return this.digitsLimit(this.form.numDigitsA);
          },
        },
        numDigitsB: {
          required,
          greaterThanZero: function () {
            return this.greaterThanZero(this.form.numDigitsB);
          },
          digitsLimit: function () {
            return this.digitsLimit(this.form.numDigitsB);
          },
          divideRule: function () {
            if (
              this.form.mathType == "/" &&
              this.form.numDigitsB > this.form.numDigitsA &&
              this.form.numDigitsB <= this.factorDigitsMax
            ) {
              return false;
            } else {
              return true;
            }
          },
        },
      },
    };
  },

  methods: {
    setMaxQuestions(numQuestions, max) {
      if (this.form.mathType != "/") {
        max = 300;
      } else if (this.form.numDigitsA <= 5 || this.form.numDigitsB <= 5) {
        max = 100;
      } else {
        max = 10;
      }
      this.maxQuestions = max;
      if (numQuestions != "" && numQuestions > max) {
        return false;
      } else {
        return true;
      }
    },
    greaterThanZero(numDigits) {
      if (numDigits != "" && numDigits <= 0) {
        return false;
      } else {
        return true;
      }
    },
    digitsLimit(numDigits, max) {
      if (this.form.mathType == "/") {
        this.factorDigitsMax = 7;
        max = 8;
      } else {
        this.factorDigitsMax = 14;
        max = 15;
      }
      if (numDigits != "" && numDigits >= max) {
        return false;
      } else {
        return true;
      }
    },
    async makeQuestions() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.generateQuestions(this.form);
          resolve();
        }, 250); //delated here because without overlay not working on some divide problems
      });
    },
    async train() {
      this.showOverlay = true;
      const result = this.validate();
      if (result) {
        this.setMathForm(this.form);

        await this.makeQuestions();

        this.$router.push("/MathTraining");
      }
      this.showOverlay = false;
    },
    async validate() {
      this.$v.form.$touch();
      return await this.v$.$validate();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    ...mapMutations(["setMathForm"]),
    ...mapActions(["setFormData", "setQuestions", "generateQuestions"]),
    ...mapGetters([]),
  },
  mounted() {},
};
</script>
