<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('MathForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h2 class="headline font-weight-bold mb-3">
          Math Training: {{ mathTitle }}
        </h2>
      </b-col>
    </b-row>
    <div v-if="!gameOver">
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="headline font-weight-bold mb-3">
            Question {{ questionIndex }} of {{ numQuestions }}
          </h2>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button class="mx-3" variant="light" size="lg" @click="skip"
            >Skip</b-button
          >
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <h1 class="font-weight-bold mb-0">{{ question }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row class="justify-content-center text-center">
            <b-col lg="2" md="4" sm="5" cols="6">
              <b-form-input
                v-model.lazy="userAnswer"
                type="tel"
                size="lg"
                autofocus
                @keyup.enter="submit"
              ></b-form-input> </b-col></b-row></b-col
      ></b-row>
      <b-row align-h="center">
        <b-col cols="12" class="justify-content-center text-center">
          <b-button pill variant="primary" size="lg" @click="submit"
            >Submit</b-button
          >
        </b-col>
      </b-row>
    </div>
    <div v-if="gameOver">
      <b-row class="m-4 text-center">
        <b-col cols="12">
          <h2 class="headline font-weight-bold mb-3">
            Score: {{ percentScore }}% {{ numMissedQuestions }} /
            {{ numQuestions }} Correct
          </h2>
        </b-col>
      </b-row>
      <b-row align-h="center" class="text-center">
        <b-col cols="12" sm="2" class="">
          <b-button class="mb-3" pill variant="primary" size="lg" @click="play"
            >Try Again?</b-button
          >
        </b-col>
        <b-col cols="12" sm="2" class="">
          <b-button
            class="mx-3 mb-3"
            pill
            variant="primary"
            size="lg"
            @click="newQuestions"
            >New Questions</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "MathTraining",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      mathForm: null, //once set should contain data: numQuestions, mathType, numDigitsA, numDigitsB
      numQuestions: null,
      mathType: null,
      userAnswer: null,
      questionList: null,
      question: null,
      answer: null,
      questionIndex: 0,
      missedQuestionsIndexes: new Set(),
      correctAnswers: 0,
      gameOver: false,
    };
  },
  validations() {
    return {};
  },
  computed: {
    percentScore() {
      if (this.missedQuestionsIndexes.size === 0) {
        return 100;
      } else {
        return (
          ((this.numQuestions - this.missedQuestionsIndexes.size) /
            this.numQuestions) *
          100
        ).toFixed(2);
      }
    },
    numMissedQuestions() {
      return this.numQuestions - this.missedQuestionsIndexes.size;
    },
    mathTitle() {
      let mathType = this.getMathForm()?.mathType;
      if (mathType == "/") {
        mathType = "Division";
      } else if (mathType == "*") {
        mathType = "Multiplication";
      } else if (mathType == "+") {
        mathType = "Addition";
      } else if (mathType == "-") {
        mathType = "Subtraction";
      } else {
        mathType = "";
      }
      return mathType;
    },
  },
  watch: {
    //Adds commas while user types larger numbers such as 1,000
    userAnswer: function (newValue) {
      let result = "";
      if (newValue.substring(0, 1) === "-") {
        result += "-";
      }
      result += newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.$nextTick(() => (this.userAnswer = result));
    },
  },
  methods: {
    submit() {
      if (this.userAnswer == this.answer) {
        this.successToast();
        this.correctAnswers++;
        setTimeout(() => {
          this.nextQuestion();
        }, "1500");
      } else {
        this.missedQuestionsIndexes.add(this.questionIndex);
        this.failToast();
      }
    },
    nextQuestion() {
      this.questionIndex++;
      this.userAnswer = "";

      if (this.questionList != null && !this.questionList.length <= 0) {
        let questionObj = this.questionList.pop();
        this.answer = questionObj.answer;
        this.question = questionObj.question;
      } else {
        this.gameOver = true;
      }
    },
    skip() {
      this.missedQuestionsIndexes.add(this.questionIndex);
      this.$bvToast.toast(this.question + " = " + this.answer, {
        title: "Answer",
        autoHideDelay: 3000,
        variant: "warning",
      });
      this.nextQuestion();
    },
    play() {
      this.gameOver = false;
      this.questionIndex = 0;
      this.correctAnswers = 0;
      this.missedQuestionsIndexes = new Set();
      if (this.getQuestions() != null) {
        this.questionList = this.getQuestions().map((x) => x);
        this.mathType = this.getMathForm().mathType;
        this.mathForm = this.getMathForm();
        this.numQuestions = this.getMathForm().numQuestions;
        this.nextQuestion();
      }
    },
    async newQuestions() {
      await this.generateQuestions(this.getMathForm());
      this.play();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    generateQuestions() {},
    ...mapMutations(["setQuestions"]),
    ...mapActions(["setFormData", "generateQuestions"]),
    ...mapGetters(["getMathForm", "getQuestions"]),
    successToast() {
      this.$bvToast.toast(this.question + " = " + this.answer, {
        title: "Correct!",
        autoHideDelay: 3000,
        variant: "success",
      });
    },
    failToast() {
      this.$bvToast.toast(this.question + " ≠ " + this.userAnswer, {
        title: "Incorrect",
        autoHideDelay: 3000,
        variant: "danger",
      });
    },
  },
  mounted() {
    if (this.getMathForm() == null) {
      this.$router.push("/MathForm");
    } else {
      this.play();
    }
  },
};
</script>
