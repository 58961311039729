import CardService from "../services/CardService.js";

const NumbersModule = {
  state: {
    cardsForm: null, //contains data: numberOfCards, studySeconds, intermissionSeconds, recallSeconds,  //once has been set
    cardsToRemember: null,
    heartsIndexList: [39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51],
  },
  getters: {
    getCardsForm: (state) => {
      return state.cardsForm;
    },
    getCardsToRemember: (state) => {
      return state.cardsToRemember;
    },
    getHeartsIndexList: (state) => {
      return state.heartsIndexList;
    },
  },
  mutations: {
    setCardsForm(state, cardsForm) {
      state.cardsForm = cardsForm;
    },
    setCardsToRemember(state, cardsToRemember) {
      state.cardsToRemember = cardsToRemember;
    },
  },
  actions: {
    async createCardsList({ commit }, payload) {
      commit("setCardsForm", payload);
      let cardsToRemember = CardService.createCardsToRemember(payload);
      commit("setCardsToRemember", cardsToRemember);
    },
  },
};

export default NumbersModule;
