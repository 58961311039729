import Vue from "vue";
import Vuex from "vuex";
import MathModule from "./mathModule.js";
import NumbersModule from "./numbersModule.js";
import CardsModule from "./cardsModule.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    mathModule: MathModule,
    numbersModule: NumbersModule,
    cardsModule: CardsModule,
  },
});
