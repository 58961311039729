<template class="">
  <b-container fluid>
    <b-row class="m-4 text-center">
      <b-col md="3" sm="12" cols="12" class="text-md-left text-sm-center">
        <b-button
          class="mr-3"
          pill
          variant="secondary"
          size="lg"
          @click="$router.push('NumbersForm')"
          >Change Settings</b-button
        >
      </b-col>
      <b-col md="4" sm="12" offset-md="1" class="justify-content-center">
        <h2 class="mb-3">Intermission</h2>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <p class="lead mb-3">Can use this time to reinforce memory</p>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="12" class="justify-content-center text-center">
        <h4 class="mb-3">Seconds Remaining: {{ intermissionSeconds }}</h4>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col cols="6" class="justify-content-center text-center">
        <router-link :to="afterIntermissionRoute"
          ><b-button pill variant="secondary"
            >Skip Wait Time</b-button
          ></router-link
        >
      </b-col>
    </b-row>

    <div></div>
  </b-container>
</template>

<script>
import useVuelidate from "@vuelidate/core";
// import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "IntermissionTime",
  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      intermissionSeconds: null,
      afterIntermissionRoute: "",
    };
  },
  validations() {
    return {};
  },
  computed: {},
  watch: {
    intermissionSeconds: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.intermissionSeconds--;
          }, 1000);
        } else if (value != null) {
          this.$router.push(this.afterIntermissionRoute);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(["createNumbersList"]),
    ...mapGetters(["getNumbersForm", "getCardsForm", "getNumbersToRemember"]),
    successToast() {
      this.$bvToast.toast(this.question + " = " + this.answer, {
        title: "Correct!",
        autoHideDelay: 3000,
        variant: "success",
      });
    },
    failToast() {
      this.$bvToast.toast(this.question + " ≠ " + this.userAnswer, {
        title: "Incorrect",
        autoHideDelay: 3000,
        variant: "danger",
      });
    },
  },
  mounted() {
    if (
      this.$route.params.intermissionType == "numbers" &&
      this.getNumbersForm() != null
    ) {
      this.intermissionSeconds = this.getNumbersForm().intermissionSeconds;
      this.afterIntermissionRoute = "/NumbersRecall";
    } else if (
      this.$route.params.intermissionType == "cards" &&
      this.getCardsForm() != null
    ) {
      this.intermissionSeconds = this.getCardsForm().intermissionSeconds;
      this.afterIntermissionRoute = "/CardsRecall";
    } else {
      this.$router.push("/");
    }
  },
};
</script>
